import React from "react";
import Login from "../components/Login";

const Loginn = () => {
  return (
    <div className="h-screen">
      <div>
        <Login />
      </div>
    </div>
  );
};

export default Loginn;

// className="flex justify-center items-center" style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', width: '100vw', height: '100vh' }}