import React from "react";
import Signups from "../components/Signup";

const Signup = () => {
    return (
        <div>
            <div>
                <Signups />
            </div>
        </div>
    );
};

export default Signup;